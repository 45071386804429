<!-- eslint-disable -->
<template>
  <!-- Content Section Start -->
  <div class="mt-6"> 
    <!-- Page title Section Start -->
    <div id="page-title" class="page-title-block" :style="style">
      <div class="container">
        <h1 class="post-title">Recover your password</h1>
      </div>
    </div>
    <!-- Page title Section End --> 
    <!-- Benefits Start -->
    <div id="page-content">
      <div class="container">
        <div class="row">
          <div class="col-md-3 sidebar">
            <div class="widget-area">
              <aside id="categories-1" class="widget widget_categories">
                <h4 class="widget-title">Profile</h4>
                <ul>
                  <li
                    v-for="link in navLinks"
                    :key="link.id"
                  >
                    <router-link
                      :to="link.to"
                    >
                      {{ $t(link.label) }}
                    </router-link>
                  </li>
                </ul>
              </aside>
            </div>
          </div>
          <div class="col-md-9 order-first order-md-last">
            <article class="post">
              <div class="container">
                <div class="contact-section">
                  <div class="col-md-1" />
                  <div class="col-md-10 text-center">
                    <div class="sec-title text-center">
                      <h3>Recover Password</h3>
                    </div>
                    <div class="form-row">
                      <h5 class="pl-1">Please enter your email address</h5>
                      <div class="form-group col-md-12">
                        <input v-model="user.email" type="text" class="form-control" placeholder="Email Address">
                      </div>
                    </div>
                    <div class="form-group">
                      <button type="submit" class="btn btn-save" @click.prevent="onSend">Send</button>
                    </div>
                    <div class="col-md-1" />
                  </div>
                </div>
              </div>
              <!-- Hero Section End -->
            </article>
          </div>
        </div>
      </div>
    </div>
    <!-- Benefits End --> 
  </div>
</template>

<script>
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import { nanoid } from 'nanoid';
  import { validateEmail } from '@/utils/validate-email';
  import { FORGOT_PASSWORD_LINKS } from '@/constants/navbar-links';
  import backgroundBanner from '@/assets/jpg/profile.jpg';

  export default Vue.extend({
    name: 'elros-forgot-password',
    data: () => ({
      style: { backgroundImage: `url(${backgroundBanner})` },
      user: {
        email: '',
        type: 'users',
      },
    }),
    computed: {
      ...mapGetters({
        getUser: 'session/getUser',
      }),
      navLinks() {
        return FORGOT_PASSWORD_LINKS
          .filter(item => !item.needsAuth)
          .map(item => ({ ...item, id: nanoid() }));
      },
    },
    created() {
      const { query } = this.$router.currentRoute;
      if (query && query.reset_password_token) {
        this.confirmUser(query.reset_password_token);
      }
      if (this.getUser.id) {
        this.user = { ...this.getUser };
      }
    },
    methods: {
      ...mapActions({
        forgotPassword: 'session/forgotPassword',
        updatePassword: 'session/updatePassword',
      }),
      onSend() {
        const isValidEmail = validateEmail(this.user.email);

        if (!isValidEmail) {
          this.$toasts.toast({
            id: 'warning-toast',
            intent: 'warning',
            message: 'Please enter a valid email address',
            title: 'Attention',
          });

          return;
        }
        this.forgotPassword({ credentials: { ...this.user } });
      },
    },
  });
</script>
<style>
  .btn-save {
    background: #ea721c !important;
    background-color: #ea721c !important;
    border-radius: 4px;
    color: #1d1d1d;
    font-weight: 700;
    padding: 14px 30px;
    text-transform: uppercase;
  }
</style>
